/* after changing this file run 'npm run webapp:build' */

/***************************
put Sass variables here:
eg $input-color: red;
****************************/
// Override Boostrap variables
// Import Bootstrap source files from node_modules
@import '~bootswatch/dist/flatly/variables';
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/src/index.scss';
@import '~bootswatch/dist/flatly/bootswatch';
/* Dirty fix on navbar active link with bootswatch theme */
$navbar-dark-hover-color:           rgba($white, .90) !default;
nav li.router-link-active .navbar-dropdown-menu {
  color: $navbar-dark-active-color;
}
/* Modificar el estilo hover*/
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #18bc9c !important;
}

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active {
  color: #18bc9c !important;
}
/* jhipster-needle-scss-add-vendor JHipster will add new css style */
